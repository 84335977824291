import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function BackButton({ backFunction }) {
  return (
    <div className="uk-grid-item-match">
      <div className="uk-card uk-card-body uk-card-default uk-card-small uk-flex uk-flex-center uk-flex-middle uk-text-center">
        <button
          className="uk-button uk-width-1-1 uk-button-link uk-link-reset"
          onClick={backFunction}
          aria-label="Zurück"
        >
          Zurück
        </button>
      </div>
    </div>
  );
}

function ForwardButton({ data, forwardFunction }) {
  return (
    <div className="uk-grid-item-match">
      <div className="uk-card uk-card-body uk-card-small uk-flex uk-flex-center uk-flex-middle uk-text-center uk-card-default">
        <button
          aria-label={data.overviewLabel}
          className="uk-button uk-width-1-1 uk-link-reset uk-flex uk-flex-column uk-flex-middle uk-background-default uk-text-break"
          onClick={forwardFunction(data)}
        >
          <i
            className="uk-icon"
            data-uk-icon={`icon: ${data.overviewIcon}; ratio: 5`}
          />
          {data.overviewLabel}
        </button>
      </div>
    </div>
  );
}

function ServiceOverview({ breadcrumb }) {
  const [path, setPath] = useState(breadcrumb.length > 1 ? breadcrumb[1] : []);

  useEffect(() => {
    setPath(breadcrumb.length > 1 ? [breadcrumb[breadcrumb.length - 1]] : []);
  }, [breadcrumb]);

  if (!path || !path.length) return <div />;

  const handleBack = (e) => {
    e.preventDefault();
    let _path = [...path]; //copy of path array
    _path.pop();
    setPath(_path);
  };

  const handleForward = (item) => {
    return function (e) {
      e.preventDefault();
      setPath([...path, item]);
    };
  };

  return (
    <section className="uk-section uk-section-default block">
      <div className="uk-container uk-container-expand">
        <div data-uk-grid="">
          <div className="uk-width-1-4@m">
            <div className="uk-grid" data-uk-grid="">
              <div className="uk-width-1-2 uk-height-small uk-visible@l" />
              <div className="uk-width-1-2@l">
                <ul
                  id="service-tab"
                  className="uk-tab-left uk-flex-center"
                  data-uk-tab="connect: #component-tab-left; animation: uk-animation-fade"
                >
                  {path.map((item, index) => {
                    return (
                      <li
                        key={item._id + "-title"}
                        className={
                          index !== path.length - 1
                            ? "uk-disabled"
                            : "uk-active"
                        }
                      >
                        <a
                          className={`uk-text-right@m uk-flex uk-flex-column uk-flex-middle ${
                            index === 0 ? "uk-padding-remove" : ""
                          }`}
                          href="#"
                        >
                          {index > 0 && (
                            <>
                              <i
                                className="uk-icon"
                                data-uk-icon={`icon: ${item.overviewIcon}; ratio: 5`}
                              />
                              <div className="uk-text-break">
                                {item.overviewLabel}
                              </div>
                            </>
                          )}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m">
            <ul id="component-tab-left" className="uk-switcher">
              {path.map((item, index) => {
                if (index !== path.length - 1) return <></>;
                return (
                  <li key={item._id + "-content"}>
                    <div
                      className="uk-child-width-1-5@l uk-child-width-1-3@m uk-child-width-1-2@s uk-grid uk-grid-match"
                      data-uk-grid=""
                      data-uk-scrollspy="cls: uk-animation-fade; target: .uk-card; repeat: true"
                    >
                      {item.children.map((data) =>
                        data.children.length > 0 ? (
                          <ForwardButton
                            key={data._id}
                            data={data}
                            forwardFunction={handleForward}
                          />
                        ) : (
                          <div className="uk-grid-item-match" key={data._id}>
                            <div className="uk-card uk-card-body uk-card-small uk-flex uk-flex-center uk-flex-middle uk-text-center uk-card-default">
                              <Link
                                aria-label={data.overviewLabel}
                                className="uk-link-reset uk-flex uk-flex-column uk-flex-middle uk-text-break"
                                to={
                                  data.page && data.page.url
                                    ? data.page.url
                                    : data.url
                                }
                              >
                                <i
                                  className="uk-icon"
                                  data-uk-icon={`icon: ${data.overviewIcon}; ratio: 5`}
                                />
                                {data.overviewLabel}
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                      {path.length > 1 && (
                        <BackButton backFunction={handleBack} />
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps({ breadcrumb }) {
  return { breadcrumb };
}

export default connect(mapStateToProps)(ServiceOverview);
